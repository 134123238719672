import {Button} from './Button';
import './styles/MainCardDoctor.css'
import {Title} from './Title'

export function MainCardDoctor(){
    return (
        <section className='main-maincardDoctor'>
            <Title text={'Equipo'} blue={true}/>
            <div className='main-cardDoctor'>
                <div className='cardDoctor-Sec' >
                    <div className='cardDoctor-imgSec'>
                    <img className='cardDoctor-img' src='/images/drs/drfrancisco.jpg' alt="foto dr francisco gomez" />
                    </div>
                <div className='cardDoctor-infoSec'>
                    <h3 className='cardDoctor-name'>Dr. Francisco Gómez Aguayo</h3>
                    <h4 className='cardDoctor-profesion'>Oftalmologo</h4>
                    <h4 className='cardDoctor-especialist'>Especialista en Glaucoma</h4>
                    <Button  text='Ver más' color='blue' refe='/drfrancisco' />
                </div>
                </div>
                <div className='cardDoctor-LogoSec'>
                    <img className='cardDoctor-logoclinica' src="../images/drs/logo-clinica.png" alt="logo clinica" />
                    <p className='cardDoctor-logopara'>Somos un equipo de oftalmologos especialistas expertos en cada uno de tus problemas en los ojos</p>
                </div>
                
            </div>
        </section>
    )
}