import { Footer } from "../Footer"
import { MainNavBar } from "../MainNavBar"
import { PageDisease } from "../PageDisease"

export function Orbita(){
    let titleofpage = {
        imgsrc:'../images/diseaseimages/orbita.svg',
        title:'Órbita y Oculoplastía',
        subtitle:'Cirugía Oculoplastica / Blefaroplastía'
    }
    let textofpage ={
        'Edad del Paciente:':'45 años o más.',
        'Candidatos:':'Pacientes que presenten laxcitud ocular o bolsas en los ojos.',
        'Procedimiento:':'Se lleva acabo una corrección funcional y estética de los párpados y las vías lagrimales retirando el exceso de tejido.',
        'Duración de la Cirugía':'1.5hrs.',
        'Recuperación:':'Ambulatoria, y se retiran las puntadas en una semana.'
    }
    return (<>
        <title>Consultorio Oftalmológico | Orbita y Oculoplastia</title>
        <MainNavBar/>
        <PageDisease title={titleofpage} text={textofpage}/>
        <Footer/>
    </>
        
    )
}