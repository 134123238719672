import './styles/Button.css'
import PropTypes from 'prop-types'

export function Button({text,color,refe}){
    return <a href={refe} className={"btn "+ color}>
        {text}
    </a>
}

Button.propTypes = {
    text: PropTypes.string.isRequired,
    color:PropTypes.string.isRequired,
    refe:PropTypes.string.isRequired
}