import { Backlink } from "../Backlink";
import { Footer } from "../Footer";
import { MainNavBar } from "../MainNavBar";
import '../styles/Drfrancisco.css';
import { Whatsappbtn } from '../Whatsappbtn';
export function DrFranciscoPage(){
    return (<>
        <title>Consultorio Oftalmológico | Dr Francisco Gomez Aguayo</title>
        <meta name="description" content="El Doctor Francisco Gomez Aguayo, es un especialista en Glaucoma egresado de la Universidad de Guadalajara" />
        <MainNavBar/>
        <Whatsappbtn/>
        <div className="main-drfrancisco">
            <div className="submain-drfrancisco">
                <div className="imgSec-drfrancisco">
                    <img className="img-drfrancisco" src="../../images/drs/drfrancisco.jpg" alt="imagen dr francisco" />
                </div>
                <div className="infoSec-drfrancisco">
                    <div className="header-drfrancisco">
                        <h3 className="name-drfrancisco">Dr. Francisco Gomez Aguayo</h3>
                        <h4 className="job-drfrancisco">Especialista en Glaucoma</h4>
                    </div>
                    <div className="sec-drfrancisco">
                        <h3 className="titleSec-drfrancisco">Educación</h3>
                        <p className="details-drfrancisco"> 
                            <li>
                               <strong>Medico Cirujano y Partero</strong> -Universidad de Guadalajara <br /> 
                            </li>
                            <li>
                                <strong>Oftalmología</strong> -Hospital Civil de Guadalajara,Fray Antonio Alcalde, UdeG <br />
                            </li>
                            <li>
                               <strong>Glaucoma</strong> -Hospital Civil de Guadalajara 
                            </li>
                            
                        </p>
                    </div>
                    <div className="sec-drfrancisco">
                        <h3 className="titleSec-drfrancisco">Certificaciones</h3>
                        <p className="details-drfrancisco">
                            <li>
                                <strong>Cédula prof. gral. 5970139</strong> -Universidad de Guadalajara
                            </li> 
                            <li>
                                <strong>Cédula prof. esp. 8590928</strong> -Universidad de Guadalajara
                            </li>
                            
                        </p>
                    </div>
                    <Backlink/>
                </div>
                
            </div>
            
        </div>
        <Footer/>
    </>)
}