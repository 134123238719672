import './styles/CardDisease.css';
import './styles/GridDisease.css'
import {CardDisease} from './CardDisease'
import { Title } from './Title';

export function MainGridDisease(){
    return <section className="main-div-gridDisease">
        <Title text={'Padecimientos'} blue={true}/>
        
        <div className='div-gridDisease'>
            <CardDisease text={'Retina'} imgName={'retina'}  refe={'/retina'}/>
            <CardDisease text={'Cirugia Láser'} imgName={'laser'}  refe={'/cirugialaser'}/>
            <CardDisease text={'Cataratas'} imgName={'cataratas'}  refe={'/cataratas'}/>
            <CardDisease text={'Glaucoma'} imgName={'glaucoma'}  refe={'/glaucoma'}/>
            <CardDisease text={'Orbita y Oculoplastia'} imgName={'orbita'}  refe={'/orbita'}/>
            <CardDisease text={'Lentes'} imgName={'lentes'}  refe={'/lentes'}/>
            <CardDisease text={'Estudios'} imgName={'estudios'}  refe={'/estudios'}/>
            <CardDisease text={'Otros'} imgName={'otros'}  refe={'/otros'}/>
        </div>
    </section> 
}