import { Footer } from "../Footer"
import { MainNavBar } from "../MainNavBar"
import { PageDisease } from "../PageDisease"

export function Retina(){
    let titleofpage = {
        imgsrc:'../images/diseaseimages/retina.svg',
        title:'Desprendimiento de Retina',
        subtitle:'Vitrectomía'
    }
    let textofpage ={
        'Edad del Paciente:':'No Aplica',
        'Definicion:':'Es la separación de la retina a la pared ocular a la que normalmente esta pegada. Esto es ocasionado por un liquido que ingresó por medio de un desgarre o un agujero retiniano.',
        'Candidatos:':'Pacientes que presentan desprendimiento de retina',
        'Procedimiento:':'Es una técnica de microcirugía ocular de mínima invasión por medio de la cual se extrae el vítrio para sustituirlo, generalmente con gas o líquido.',
        'Duracion de la Cirugia:':'1.5 a 2hrs',
        'Recuperación:':'Ambulatoria, y se retiran los parches a las 12hrs.'
    }
    return (<>
        <title>Consultorio Oftalmológico | Retina</title>
        <MainNavBar/>
        <PageDisease title={titleofpage} text={textofpage}/>
        <Footer/>
    </>
        
    )
}