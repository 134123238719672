import { Footer } from "../Footer"
import { MainNavBar } from "../MainNavBar"
import { PageDisease } from "../PageDisease"

export function Catarata(){
    let titleofpage = {
        imgsrc:'../images/diseaseimages/cataratas.svg',
        title:'Cataratas',
        subtitle:'Cirugía de cataratas por facoemulsifación'
    }
    let textofpage ={
        'Edad del Paciente:':'45 años o más',
        'Definicion:':'La catara se caracteriza por la perdida de transparencia normal del lente natural del ojo, que se llama "cristalino".',
        'Candidatos:':'Adultos con dificultad para mirar claramente, producto del oscurecimiento del lente natural del ojo por el paso del tiempo.',
        'Procedimiento:':'Se trata de una cirugía para volver a ver claro de nuevo, se realiza por medio de ultra sonido y/o láser, y posteriormente se implanta un lente intraocular.',
        'Tipos de lentes intre oculares:':<>
        <li><strong>Lente Mono Focal</strong>- Vision Lejana Perfecta.</li>
        <li><strong>Lente Mono Focal Tórico</strong>- Visión Lejana perfecta + Elimina el astigmatismo.</li>
        <li><strong>Lente Bifocal</strong>- Visión lejana y cercana.</li>
        <li><strong>Lente Trifocal</strong>- Visión, lejana e intermedia.</li>
        <li><strong>Lente Trifocal Tórico</strong>- Visión, lejana e intermedia + Elimina el astigmatismo.</li>
        </>,
        'Duracion de la Cirugia:':'30 minutos.',
        'Recuperación:':'Ambulatoria, y se retiran los parches a las 12hrs.'
    }
    return (<>
        <title>Consultorio Oftalmológico | Cataratas</title>
        <MainNavBar/>
        <PageDisease title={titleofpage} text={textofpage}/>
        <Footer/>
    </>
        
    )
}