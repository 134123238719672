import './styles/Testimonials.css'
export function Testimonials(){
    return(
        <section className="main-testimonials">
            <div className="titleSec-testimonials">
                <h2 className="title-testimonial">Lo Que Dicen Nuestros Clientes</h2>
            </div>
            <div className="infoSec-testimonials">
                <div className="info-testimonials">
                    <h3 className="infotitle-testimonials">“Excelente Servicio”</h3>
                    <p className="infopara-testimonials">El servicio es excelente, espero que más gente cuide de sus ojos aquí</p>
                    <p className="infoclient-testimonials">Daniela</p>
                </div>
                <div className="info-testimonials">
                    <h3 className="infotitle-testimonials">“Confiabilidad y Seguridad”</h3>
                    <p className="infopara-testimonials">Me siento muy bien después de mi operación, sin duda alguna recomendaría este consultorio</p>
                    <p className="infoclient-testimonials">Margarita</p>
                </div>
                <div className="info-testimonials">
                    <h3 className="infotitle-testimonials">“Muy buen trato”</h3>
                    <p className="infopara-testimonials">El doctor es muy formal, amable y de buen trato. Sin duda detalles que se aprecian </p>
                    <p className="infoclient-testimonials">Arturo</p>
                </div>
            </div>
        </section>
    )
}