import React from 'react'
import ReactDom from 'react-dom/client'
import {MainGridDisease} from './MainGridDisease'
import {MainCardDoctor} from './MainCardDoctor'
import {MainInitialPage} from './MainInitialPage'
import { Location } from './Location'
import { Footer } from './Footer'
import { RutasDisease } from './RutasDisease'
import { BrowserRouter } from 'react-router-dom'


const root = ReactDom.createRoot(document.getElementById("root"));

root.render(<>
    <BrowserRouter>
        <RutasDisease/>
    </BrowserRouter>

</>)