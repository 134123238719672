import { Backlink } from './Backlink';
import './styles/PageDisease.css'
import { Whatsappbtn } from './Whatsappbtn';
export function PageDisease({title,text}){
    let secofpage = [];
    for(let x in text){
        secofpage.push(
                <>
                    <h2 className="titleSec-pagedisease">{x}</h2>
                            <p className="paraSec-pagedisease">
                                {text[x]}
                            </p>
                </>
        )
    }
    
    return (
        <>
            <Whatsappbtn/>
            <div className="main-pagedisease">
                <div className="header-pagedisease">   
                    <img className='img-pagedisease' src={title.imgsrc} />
                    <div className="headertext-pagedisease">
                        <h2 className="title-pagedisease">{title.title}</h2>
                        <h3 className="subtitle-pagedisease">{title.subtitle}</h3>
                    </div>
                </div>
                <div className="info-pagedisease">
                    {secofpage.map((sec,i)=>{
                        return (<div key={i} className="infoSec-pagedisease">{sec}
                        </div>    
                        )
                    })}
                    <Backlink/>
                </div>
                
            </div>
        </>
        
    )
}