import { Footer } from "../Footer"
import { MainNavBar } from "../MainNavBar"
import { PageDisease } from "../PageDisease"

export function Otros(){
    let titleofpage = {
        imgsrc:'../images/diseaseimages/otros.svg',
        title:'Otros...',
        subtitle:'Más cirugías en Clínica de Ojos Guadalajara'
    }
    let textofpage ={
        'Algunas otras de la cirugías que realizamos en clínica de ojos Guadalajara son:':<><li>Estrabismo</li><li>Retinopatía Diabética</li><li>Inflamacion Ocular</li> <li>Transplantes de Cornea</li> <li>Oftalmología Pediátrica</li></>

    }
    return (<>
        <title>Consultorio Oftalmológico | Otros</title>
        <MainNavBar/>
        <PageDisease title={titleofpage} text={textofpage}/>
        <Footer/>
    </>
        
    )
}