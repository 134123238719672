import './styles/Backlink.css'

export function Backlink(){
    return (
        <>
            <a className="return-backlink" href="/">
                <img className="img-backlink" src="../images/undo.svg" alt="" />
                <p className='text-backlink'>Volver</p>
            </a>
        </>
    )
}