import  '../src/styles/Whatsappbtn.css'
export function Whatsappbtn(){
    return (
        <>
        <div className='links_container'>
            <div className="btn_container whatsapp_link">
            <a  href="https://api.whatsapp.com/send?phone=3360592782" target={"_blank"} >
              <img className="sidelink_icon" src="../icons/whatsapp (2).svg" alt="" />  
            </a>
            </div>
            <div className='btn_container instagram_link'>
                <a  href="https://www.instagram.com/drfranciscoftalmologo/?fbclid=IwAR2I0ct9zMPkafPQNJDVoIDVfgfPKEd9cGkZ8VGabTkjgnxmjO1rPh_O-AE" target={"_blank"} >
                <img className="sidelink_icon" src="../icons/instagram (1).svg" alt="" />  
                </a>
            </div>
            <div className='btn_container facebook_link'>
                <a  href="https://www.facebook.com/profile.php?id=100090649421991" target={"_blank"} >
                <img className="sidelink_icon" src="../icons/facebook (1).svg" alt="" />  
                </a>
            </div>
        </div>
        
        </>
    )
}