import './styles/Footer.css'
export function Footer(){
    return (
        <>
        <footer className="mainSec-footer">
            <div className="submainSec-footer">
                <div className="sec-footer">
                    <img className="img-footer" src="../images/footer/logo-clinicanegativo.png" alt="" />
                    <p className="aboutText-footer">Somos un equipo de oftalmologos especialistas especialistas en tus ojos</p>
                </div>
                <div className="sec-footer">
                    <div className="headerSec-footer">
                        <h3 className="titleSec-footer">Acceso Rapido</h3>
                        <div className="menuImgSec-footer" onClick={collapseMenu}><img className="menuImg-footer" src="../icons/footer-menu.svg" alt="menu icon" /></div>
                    </div>
                    <div className="linkSec-footer">
                        <a className='link-footer' href="/">Inicio</a>
                        <a className='link-footer' href="/about">Sobre Nosotros</a>
                        <a className='link-footer' href="/contact">Contacto</a>
                    </div>
                </div>
                <div className="sec-footer">
                <div className="headerSec-footer">
                        <h3 className="titleSec-footer">Contactanos</h3>
                        <div className="menuImgSec-footer rotate" onClick={collapseMenu}><img className="menuImg-footer" src="../icons/footer-menu.svg" alt="menu icon" /></div>
                    </div>
                    <div className="linkSec-footer collapseLinks">
                        <a className='link-footer' href="https://www.facebook.com/profile.php?id=100090649421991" target={"_blank"} >Facebook</a>
                        <a className='link-footer' href="https://www.instagram.com/drfranciscoftalmologo/?fbclid=IwAR2I0ct9zMPkafPQNJDVoIDVfgfPKEd9cGkZ8VGabTkjgnxmjO1rPh_O-AE" target={"_blank"} >Instagram</a>
                        <a className='link-footer' href="https://api.whatsapp.com/send?phone=3360592782"
                        target={"_blank"} >Whatsapp</a>
                        <a className='link-footer' href="mailto:fga.opht@hotmail.com" target={"_blank"} >fga.opht@hotmail.com</a>
                    </div>
                </div>
            </div>
            <div className='rights-footer'>
                &#169; Todos los derechos reservados a Dr Francisco Gomez Aguayo
            </div>
        </footer>
        </>
    )
}

function collapseMenu(test){
    let currentSec = test.target.parentElement.parentElement.parentElement;
    let menuToCollapse = currentSec.getElementsByClassName('linkSec-footer')[0];
    let menuToRotate = currentSec.getElementsByClassName('menuImgSec-footer')[0];
    if(menuToCollapse.classList.contains('collapseLinks')){
        menuToCollapse.classList.remove('collapseLinks');
        menuToRotate.classList.remove('rotate');
        return;
    }
    menuToCollapse.classList.add('collapseLinks');
    menuToRotate.classList.add('rotate');
}