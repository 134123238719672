import { Footer } from "../Footer"
import { MainNavBar } from "../MainNavBar"
import { PageDisease } from "../PageDisease"

export function Glaucoma(){
    let titleofpage = {
        imgsrc:'../images/diseaseimages/glaucoma.svg',
        title:'Glaucoma',
        subtitle:'Cirugía para el Glaucoma'
    }
    let textofpage ={
        'Edad del Paciente:':'Sin Edad.',
        'Definicion:':'Es una enfermedad del nervio óptico caracterizado por la perdida del campo visual de manera progresiva que comúnmente cursa con hipertensión ocular.',
        'Candidatos:':'Pacientes con diagnostico adecuado y oportuno de glaucoma, sin respuesta a tratamiento farmacológico.',
        'Procedimiento:':<>
            <strong>Existen 2 principales procedimiento:</strong><br /><br />
            <strong>1.- Implante de válvula: </strong>Se implanta una válvula que extrae el liquido del humor acuoso, para bajar la presión intraocular. <br /><br />
            <strong>2.- Trabeculectomía: </strong>
            Se realiza una comunicación entre el segmento anterior del ojo y se crea un receptáculo para la salida del humor acuoso.
        </>,
        'Duracion de la Cirugia:':'1 hr.',
        'Recuperación:':'Ambulatoria, y se retiran los parches a las 12hrs.'
    }
    return (<>
        <title>Consultorio Oftalmológico | Glaucoma</title>
        <MainNavBar/>
        <PageDisease title={titleofpage} text={textofpage}/>
        <Footer/>
    </>
        
    )
}