import { Footer } from "../Footer"
import { MainNavBar } from "../MainNavBar"
import { PageDisease } from "../PageDisease"

export function Estudios(){
    let titleofpage = {
        imgsrc:'../images/diseaseimages/estudios.svg',
        title:'Estudios',
        subtitle:'Estudios que realizamos'
    }
    let textofpage ={
        'Estudios que realizamos en nuestras instalaciones:':<>
            <li>-Autorrefracción Computarizada (Determinar Graduación).</li>
            <li>OCT.</li>
            <li>Tomografía de coherencia óptica.</li>
            <li>Topografía Corneal / PENTACAM.</li>
            <li>Eco A-B.</li>
            <li>Campos Visuales.</li>
            <li>Fotografía de fondo de Ojo.</li>
            <li>Fluorangiografía.</li>
            <li>Paquimetría Corneal Ultrasónica.</li>
        </>,
    }
    return (<>
        <title>Consultorio Oftalmológico | Estudios</title>
        <MainNavBar/>
        <PageDisease title={titleofpage} text={textofpage}/>
        <Footer/>
    </>
        
    )
}