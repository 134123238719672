import './styles/Location.css'
import {Title} from './Title'
export function Location(){
    return (<>
    <section className='mainSec-location' id='ubicacion'>
        <Title text={'Ubicacion'} blue={false}/>
        <div className="submainSec-location">
        <div className="infoSec-location">
            <h3 className="title-location">Consultorio Ciudad Guzmán</h3>
            <a href='https://goo.gl/maps/pneNtwxn5EpAvSET8' target={"_blank"}   className='link-location'>
                <img className='linkImg-location' src="../icons/location.svg" alt="location-svg" />
                <h4 className="address-location">Gregorio Torres Quintero 73, Unión de Colonos Juan José Zaragoza, 49077 Cdad. Guzmán, Jal.</h4>
            </a  >
            <a href='tel:3414136600' target={"_blank"} className="link-location">
                <img className='linkImg-location' src="../icons/cellphone.svg" alt="" />
                <p className="telephone-location">34 1413 6600</p></a  >
            <a href='https://api.whatsapp.com/send?phone=3360592782'  target={"_blank"} className="link-location">
                <img className='linkImg-location' src="../icons/whatsapp.svg" alt="" /> 
                <p className="telephone-location">3360592782</p></a  >
                <a href='https://www.facebook.com/profile.php?id=100090649421991'  target={"_blank"} className="link-location">
                <img className='linkImg-location' src="../icons/facebook.svg" alt="" /> 
                <p className="telephone-location">Facebook</p></a  >
                <a href='https://www.instagram.com/drfranciscoftalmologo/?fbclid=IwAR2I0ct9zMPkafPQNJDVoIDVfgfPKEd9cGkZ8VGabTkjgnxmjO1rPh_O-AE'  target={"_blank"} className="link-location">
                <img className='linkImg-location' src="../icons/instagram.svg" alt="" /> 
                <p className="telephone-location">Instagram</p></a  >
            </div>
        <div className="mapSec-location">
        <iframe className='map-location' src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1127.8009011755062!2d-103.47488554444689!3d19.69834479102639!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses-419!2smx!4v1709653851987!5m2!1ses-419!2smx" 
            width={450}
            height={450}
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"/>
        </div>
        
    </div>
    </section>
    
        
        
    </>)
}