import { Footer } from "../Footer"
import { MainNavBar } from "../MainNavBar"
import { PageDisease } from "../PageDisease"

export function Laser(){
    let titleofpage = {
        imgsrc:'../images/diseaseimages/laser.svg',
        title:'Cirugía Láser',
        subtitle:'Cirugía Láser / Refractiva'
    }
    let textofpage ={
        'Edad del Paciente:':'18 a 45 años.',
        'Candidatos:':'Pacientes con Miopía, Hipermetropía y/o Astigmatismo, sin enfermedades oculares.',
        'Procedimiento:':'Es uno de los procedimientos más novedosos realizados por medio de un láser, con el cual modificamos y corregimos la forma de la cornea.',
        'Existen diversos tipos de láser:':<>
        Laser Excimer y Laser de femtosegundo.<br />Dependiendo, entonces, de la condición de tu ojo podemos aplicar los siguientes tratamientos: PRK, Lasik, Femtolasik y/o Relax smile.
        </>,
        'Duración de la Cirugía:':'15 minutos.',
        'Recuperación:':'Ambulatoria, y se retiran los parches a las 12hrs.'
    }
    return (<>
        <title>Consultorio Oftalmológico | Cirugía Laser</title>
        <MainNavBar/>
        <PageDisease title={titleofpage} text={textofpage}/>
        <Footer/>
    </>
        
    )
}