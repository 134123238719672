import {Button} from './Button'
import './styles/CardDisease.css'
import PropTypes from 'prop-types'
export function CardDisease({text,imgName,refe}){
    let url=`../images/diseaseimages/${imgName}.svg`
    return (<div className='div-cardDisease'>
        <img className='img-cardDisease' src={url} alt={text} />
        <h3 className='title-cardDisease'>{text} </h3>
        <Button text='Ver más' color='white' refe={refe}/>
    </div>)
}

CardDisease.propTypes = {
    text: PropTypes.string.isRequired,
    imgName: PropTypes.string.isRequired,
    refe: PropTypes.string.isRequired
}