import { Footer } from "../Footer"
import { MainNavBar } from "../MainNavBar"
import { Testimonials } from "../Testimonials"
import { Whatsappbtn } from "../Whatsappbtn"
import '../styles/About.css'
export function About(){
    return (
        <>
            <title>Consultorio Oftalmológico | Sobre Nosotros</title>
            <meta name="description" content="Somos un centro especializado en distintos problemas de la vista" />
            <Whatsappbtn/>
            <MainNavBar about={true}/>
            <div className="main-about">
                    <div className="titleSec-about">
                        <h2 className="title-about">Sobre Nosotros</h2>
                    </div>
                    <p className="para-about"> Somos un equipo de profesionales altamente capacitados y comprometidos con la salud visual de nuestros pacientes.Nuestro objetivo es brindar un servicio de alta calidad y personalizado a cada paciente, utilizando la tecnología más avanzada en diagnóstico y tratamiento de enfermedades oculares. Nos esforzamos por hacer de cada visita una experiencia cómoda y sin estrés para nuestros pacientes. ¡Esperamos tener la oportunidad de atender sus necesidades oftalmológicas y ayudar a mantener una buena salud visual a largo plazo!

                    </p>
                    <Testimonials/>
            </div>
            <Footer/>
        </>
    )
}