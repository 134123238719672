import { Footer } from "../Footer"
import { MainNavBar } from "../MainNavBar"
import { useState } from "react"
import { Whatsappbtn } from "../Whatsappbtn"
import '../styles/Contacto.css'
import Axios from 'axios';

export function Contacto(){
    const [data,setData] = useState({
        nombre:"",
        email:"",
        mensaje:""
    })
    const [successful,setSuccesful] = useState(false)
    function submit(e){
        e.preventDefault();
        
        Axios.post("/api",{
            nombre:data.nombre,
            email:data.email,
            mensaje:data.mensaje
        })
        .then(res=>{
            setSuccesful(true);
        })
        .catch(
            setSuccesful(()=>{
                setSuccesful(false);
            })
        )
    }

    function handle(e){
        const newdata = {...data}
        newdata[e.target.id] = e.target.value
        setData(newdata)
    }
    return (
        <>
        <title>Consultorio Oftalmológico | Contacto</title>
        <meta name="description" content="Contáctanos o visítanos, te esperamos esperando para darte el mejor trato, no esperes más y atiéndete con nosotros. Si tienes alguna otra duda, mándanos un Whatsapp, correo o llamada" />
        <MainNavBar/>
        <div className="titleSec-about">
                <h2 className="title-about">Contacto</h2>
        </div>
        <Whatsappbtn/>
        <div className="mainSec-contact">
            <div className="sec-contact">
                <h2 className="title-contact">Visítanos</h2>
                <div className="subSec-contact">
                    <h3 className="subtitle-contact">Dirección</h3>
                    <p>Gregorio Torres Quintero 73, Unión de Colonos Juan José Zaragoza, 49077 Cdad. Guzmán, Jal.</p>
                </div>
                <div className="subSec-contact">
                    <h3 className="subtitle-contact">Teléfono e email</h3>
                    <a href="tel:3414136600">341-413-6600</a> <br />
                    <a href="tel:3360592782">336-059-2782</a> <br />
                    <a href="mailto:fga.opht@hotmail.com">fga.opht@hotmail.com</a>
                </div>
            </div>
            <div className="sec-contact">
                <h2 className="title-contact">Contáctanos</h2>
                <form onSubmit={(e)=>submit(e)} className="form-contact" >
                <label className="label-form" htmlFor="nombre">Nombre <span className="span-form">(requerido)</span></label>
                <input 
                onChange={(e)=>handle(e)}
                value={data.nombre}
                maxLength = {60}
                required
                className="input-form"
                type="text"  id="nombre" placeholder="Escribe tu nombre"/>
                <label className="label-form" htmlFor="email">Email <span className="span-form">(requerido)</span></label>
                <input 
                onChange={(e)=>handle(e)}
                value = {data.email}
                required
                maxLength={50}
                className="input-form"
                type="email" id="email" placeholder="Escribe tu email"/>
                <label className="label-form" htmlFor="mensaje">Mensaje</label>
                <textarea 
                onChange={(e)=>handle(e)}
                value = {data.mensaje}
                maxLength = {250}
                className="inputarea-form"
                id="mensaje" cols="30" rows="10"></textarea>
                <button type="submit" className="btn-form">Contáctanos</button>
                {successful && <p className="successpara-form">Gracias por comunicarte con nosotros en la brevedad nos contactaremos contigo!</p>
                }
            </form>
            </div>     
            <div className="sec-contact">
                <h2 className="title-contact">Horario</h2>
                <div className="subSec-contact">
                    <h3 className="subtitle-contact">Horario Público de Visita</h3>
                    <p>Lunes a Viernes 9am a 2pm o de 4pm a 8pm</p>
                    <p>Sábados de 8am a 3pm</p>
                </div>
                
            </div>
            
            
        </div>
        <Footer/>
        </>
    )
}