import {MainGridDisease} from '../MainGridDisease'
import {MainCardDoctor} from '../MainCardDoctor'
import {MainInitialPage} from '../MainInitialPage'
import { Location } from '../Location'
import { Footer } from '../Footer'
import { MainNavBar } from '../MainNavBar'
import { Whatsappbtn } from '../Whatsappbtn'

export function Home(){
    return (<>
        <title>Consultorio Oftalmológico | Home</title>
        <Whatsappbtn/>
        <MainNavBar  inicio={true}/>
        <MainInitialPage/>
        <MainGridDisease/>
        <Location/>
        <MainCardDoctor/> 
        <Footer/>
    </>
    )
}