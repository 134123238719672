import { Route, Routes, Navigate } from'react-router-dom'
import { MainNavBar } from './MainNavBar'
import { Home } from './links/Home'
import {Contacto} from './links/Contacto'
import { About } from './links/About'
import { NotFound } from './links/NotFound'
import { Retina } from './diseasepages/Retina'
import { Otros } from './diseasepages/Otros'
import { Orbita } from './diseasepages/Orbitayoculoplastia'
import {Lentes} from './diseasepages/Lentes'
import {Glaucoma} from './diseasepages/Glaucoma'
import {Estudios} from './diseasepages/Estudios'
import {Laser} from './diseasepages/Cirugialaser'
import {Catarata} from './diseasepages/Catarata'
import { DrFranciscoPage } from './links/Drfrancisco'


export function RutasDisease(){
    return (<>
        
        <Routes>
            <Route exact path='*' element={<NotFound/>}/>
            <Route exact path='/' element={<Home/>}/>
            <Route exact path='/about' element={
                <About/>
            }/>
            <Route exact path='/contact' element={
                <Contacto/>
            }/>
            <Route exact path='/retina' element={<Retina/>}/>
            <Route exact path='/otros' element={<Otros/>}/>
            <Route exact path='/orbita' element={<Orbita/>}/>
            <Route exact path='/lentes' element={<Lentes/>}/>
            <Route exact path='/glaucoma' element={<Glaucoma/>}/>
            <Route exact path='/estudios' element={<Estudios/>}/>
            <Route exact path='/cirugialaser' element={<Laser/>}/>
            <Route exact path='/cataratas' element={<Catarata/>}/>
            <Route exact path='/drfrancisco' element={<DrFranciscoPage/>}/>
        </Routes>
        
    </>
        
    )
}