import { Footer } from "../Footer"
import { MainNavBar } from "../MainNavBar"
import { PageDisease } from "../PageDisease"

export function Lentes(){
    let titleofpage = {
        imgsrc:'../images/diseaseimages/lentes.svg',
        title:'Graduacion de Lentes',
        subtitle:'Estudio Refractivo'
    }
    let textofpage ={
        'Edad del Paciente:':'No Aplica',
        'Definicion:':'Se trata de encontrar la graduación del paciente por medios computarizados y exámenes clínicos',
        'Candidatos:':'Pacientes que presentan dificultad para ver claramente.',
        'Procedimiento:':<>
            <li> <strong>Una vez determinada la graduación se procederá elegir:</strong><br /></li>
            <li>
            <strong>Lentes de armazón: </strong>Monofocales, micas bifocales y progresivas en policarbonato y plastico acabados, Blue Ray®, Photogrey®, Transition®. <br /></li>
            <li>
            <strong>Lentes de Contacto: </strong>Lentes Rígidos y Blandos marca Bausch+Lomb® <br /> </li>
        </>,
        'Duracion del Procedimiento:':'30 minutos',
        'Tiempo de Entrega:':'1 a 2 Días Hábiles'
    }
    return (<>
        <title>Consultorio Oftalmológico | Lentes</title>
        <MainNavBar/>
        <PageDisease title={titleofpage} text={textofpage}/>
        <Footer/>
    </>
        
    )
}