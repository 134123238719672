import './styles/MainInitialPage.css'
export function MainInitialPage() {
    return(
        <section className="main-initialPage">
            <div className='imgSec-initialPage'></div>
            <div className='titleSec-initialPage'><h1 className='title-initialPage'>Consultorio Oftalmológico</h1>
            <p className='para-initialPage'>Dr. Francisco Gómez Aguayo</p>
            <div className='infoSec-initialPage'>
                <div className='address-initialPage'>Gregorio Torres Quintero 73, Unión de Colonos Juan José Zaragoza, 49077 Cdad. Guzmán, Jal.</div>
                <div className='contact-initialPage'>
                    <a className='' href="">fga.opht@hotmail.com</a>
                    <a href="tel:3360592782" >{"(336) 059-2782"}</a>
                </div>
            </div>
            
            </div>
        </section>
    )
}