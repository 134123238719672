import { Contacto } from './links/Contacto';
import './styles/MainNavBar.css';


export function MainNavBar({inicio,about,contact}){
    return (
        <nav className="main-navbar">
                <div className="logoSec-navbar">
                <img className="logoImg-navbar" src="../images/navbar/logo-clinica.png" alt="Logo clinica" />
                </div>
            <figure className="menuSec-navbar" >
                    <img src="../icons/icon-menu.svg" className="menuIcon-navbar"  alt="" onClick={collapse}/>
                </figure>      
            <div className="linksSec-navbar collapsed">
                
                <a className={inicio?'link-navbar true':' link-navbar'} href='/'>Inicio</a>
                <a className={about?'link-navbar true':' link-navbar'}  href="/about" >Sobre Nosotros</a>
                <a className={contact?'link-navbar true':' link-navbar'} href="/contact">Contacto</a>
            </div>
        </nav>
    )
}

function collapse(){
    const elementToCollapse = document.getElementsByClassName("linksSec-navbar")[0];
    if(!elementToCollapse.classList.contains('collapsed')){
        elementToCollapse.classList.add('collapsed');
        return;
    }
    elementToCollapse.classList.remove('collapsed');
    
}